import { httpService } from "@common/services/http.service";
import config from 'config'

export default function(router){
    const axiosConfig = {
        baseURL: config.apiUrl,
    }
    const httpClient = httpService(axiosConfig,{router,errorPageName:'page_error'});

    const relUrl = ({link = null,code = null}) => {
        let url = router.currentRoute.value.params.lang+'/'+config.project_key+'/v1/cms/page';
        if(link){
            url += '/view/'+link;
        } else if(code){
            url += '/code/'+code;
        } else {
            url += '/view';
        }
        return url;
    };

    const formUrl = ({action = 'submit', code}) => {
        return router.currentRoute.value.params.lang+'/'+config.project_key+'/v1/cms/form/'+action+'/'+code;
    }

    
    const getContents = (state,placementCode) => {
        let _contents = [];

        for(let _content of state.page.contents){
            if(_content.placementCode === placementCode){
                _contents.push(Object.assign({},_content));
            }
        }
        _contents.sort((a, b) => (a.position > b.position) ? 1 : -1)
        return _contents;
    }

    const state = {
        page: {},
        loading: 0
    }

    const getters = {
        getPage: (state) => state.page,
        getTemplate: (state) => {
            if(typeof state.page.template === 'object' && state.page.template !== null){
                return state.page.template;
            }
            return {};
        },
        // getContents: (state) => {
        //     if(Array.isArray(state.page.contents)){
        //         return state.page.contents;
        //     }
        //     return [];
        // },
        getContents: (state) => (placementCode) => getContents(state,placementCode),
        isLoaded: (state) => state.loading === 0,
        isError: (state) => state.loading === -1,
        getPageName: (state) => {
            if(state.page.title){
                return state.page.title;
            }
            return '';
        }
    }

    const actions = {
        fetchPage({ commit,dispatch },{link = null,code = null}){
            commit('loading',1);
            return httpClient.get(relUrl({link,code}))
                .then(response => {
                    commit('setPage',response.data);
                    commit('loading',0);
                },
                error => {
                    commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                })
        },
        submitForm({ commit,dispatch },{code,data}){
            commit('loading',1);
            return httpClient.post(formUrl({code}),data)
                .then(() => {
                    commit('loading',0);
                    dispatch('alert/success', "Zpráva odeslána", { root: true });
                },
                error => {
                    commit('loading',0);
                    dispatch('alert/error', error, { root: true });
                })

        }
    }

    const mutations = {
        setPage: (state,page) => state.page = page,
        loading: (state,status) => state.loading = status,
    }

    return {
        namespaced: true,
        state: state,
        getters: getters,
        actions: actions,
        mutations: mutations
    };

}