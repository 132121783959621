<template>
    <footer class="main-footer">
        <div class="page-container">
            <div></div>
            <Block :content="getLayoutContent('footer')" />
            <Menu :menu_key="'footer_menu'" :horizontal="true" :mobile_hidden="false"/>
        </div>
        <Copyright />
    </footer>
</template>

<script>
import useContent from "@common/composable/cms_app/Content";
import Menu from "@common/components/cms_app/menu/Menu"
import Copyright from '@common/components/layout/Copyright';
import Block from "@common/components/cms_app/Block";

export default {
    name: "MainFooter",
    components: {
        Menu,
        Copyright,
        Block
    },
    setup(){
        const { getLayoutContent } = useContent();

        return {
            getLayoutContent
        }
    }
}
</script>

<style scoped>
.main-footer{
    padding: 2rem 0 0 0;
    
    /* background-image: linear-gradient(180deg, transparent 0%, var(--light-color) 15%, var(--light-color) 50%, var(--white) 90% , var(--white)); */
    /* background-image: linear-gradient(0, var(--white) 0% , var(--white) 1rem, var(--light-color) 4rem, var(--light-color) 10rem, transparent 100%); */
   background-image: linear-gradient(180deg, transparent 0%, var(--light-color) 0, var(--light-color) 0, var(--white) 2rem , var(--white));
   margin-top: 2rem;
}
.page-container{
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem;
    align-items: flex-start;
    background: var(--white);
    gap: 1rem;
}
.main-footer ::v-deep(.nav-item a){
    color: #1d1b1a;
    color: var(--dark-color);
    padding: 0;
    margin: 0 1rem;
    text-align: center;
    display: block;
}
.main-footer ::v-deep(.nav-item a) {
    color: #000024;
    color: var(--dark-color);
    background: #E5F1FB;
    background: var(--button-color);
    padding: 0.5rem 1rem;
    margin: 0;
    /* width: 6rem; */
    text-align: center;
    /* font-weight: 700; */
    display: block;
    box-shadow: 1px 1px 3px 0px  #6b6ba3;
    box-shadow: 1px 1px 3px 0px  var(--shadow-color);
}
.main-footer ::v-deep(.block) {
    max-width: 30rem;
}

@media only screen and (max-width: 800px) {
    .page-container{
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
}
</style>
