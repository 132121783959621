<template>
    <div class="main-layout" :class="{isQbit: config.qbit}">
        <MainHeader id="main-header" @toggle-menu="toggleMenu()"/>
        <main id="main-container" v-bind="$attrs">
            <Alert />
            <router-view v-bind="$attrs" keep-alive/>
        </main>
        <MainFooter id="main-footer" />
        <!-- <CookieBar 
            theme="blood-orange"
            buttonLink="url.privacy_policy"
            buttonLinkType="internal"
        ></CookieBar> -->
    </div>
</template>

<script>
import Alert from "@common/components/Alert";
import MainHeader from "@nidl/components/layout/MainHeader";
import MainFooter from "@nidl/components/layout/MainFooter";
import config from 'config'
// import CookieBar from "@common/components/cms_app/CookieBar";

export default {
    name: "MainLayout",
    components: {
        Alert,
        MainHeader,
        MainFooter,
        // CookieBar,
    },
    setup(){
        return {
            config
        }
    }
}
</script>

<style scoped>
.main-layout{
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(30rem,70rem) minmax(0,1fr);
    grid-template-rows: max-content minmax(max-content,1fr) max-content;
    grid-template-areas: 
        'header header header'
        '. container .'
        'footer footer footer'
        ;
    grid-gap: 0;
    justify-items: stretch; 
    min-height: 100vh;
    min-height: 100vh;  
    margin: 0 auto;  
    /* width: 60rem; */
    max-width: 100%;
}
#main-header {
    grid-area: header;
}
#main-container{
    grid-area: container;
    display: flex;
    flex-direction: column;
    width: 100%;
}
#side-panel{
    grid-area: side;
}
.page-layout{
    min-height: calc(100% - 2.5rem);
    padding: 0;
    width: 100%;
}
#main-footer{
  grid-area: footer;
}

::v-deep(h1:after){
    display: block;
    content: " ";
    width: 100%;
    /* height: 1px;
    margin-top: 0.5rem; */
    
    height: 5px;
    color: #e7eaf2;
    background-color: var(--light-color);
    margin: 1rem 0;
    border-radius: 2px;
    /* background: linear-gradient(to right, transparent, grey 5%, transparent 60%); */
}
::v-deep(.page-view){
    background-color: #fffbf4;
    background-color: var(--white);
    padding: 2rem;
    box-shadow: 1px 1px 10px -2px var(--shadow-color);
}

::v-deep(.btn.btn-success){
    transition:  box-shadow 0.3s ease-in;
    
    background-color: #fffbf4;
    background-color: var(--light-color);
    color: #1d1b1a;
    color: var(--dark-color);
    box-shadow: 1px 1px 4px 1px var(--dark-color);
}
::v-deep(.btn.btn-success:hover){
    opacity: 1;
    box-shadow: 1px 1px 7px 2px var(--dark-color);
}

::v-deep(.block.offer){
    /* align-items: flex-end; */
    /* margin: 2rem auto; */
    justify-content: space-evenly;
    align-items: center;
}
::v-deep(.block.offer .text-cell){
    max-width: 15rem;
    border: 4px solid transparent;
    border-radius: 2rem;
    transition: border-color 0.4s ease;
}

::v-deep(.block.offer .text-cell:hover){
    border-color: var(--border-color);
}
::v-deep(.block.offer img){
    background-color: var(--secondary-color);
    width: 10rem;
    height: 10rem;
    border-radius: 30%;
    padding: 2rem;

        /* background-color: var(--light-color);
    background-image: 
        radial-gradient(#FFCC99 50%, #ffcc995e 60%,#FFCC99 80%,#FFCC99),
		repeating-linear-gradient(135deg, #FFCC99 0%, #ffcc998c 5%, transparent 5%, transparent 10%),
		repeating-linear-gradient(135deg, #ffcc995e 0%, #ffcc9979 5%, transparent 5%, transparent 10%);
    background-size: 100% 100%,100% 100%,10rem 10rem, 7rem 7rem; */
}
::v-deep(hr){
    border: none;
    height: 5px;
    color: #e7eaf2;
    background-color: var(--light-color);
    margin: 3rem 0;
    border-radius: 2px;
}
::v-deep(.offer-list p){
    display: inline-block;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: var(--secondary-color);
    border-radius: 0.5rem;
}
::v-deep(.block.qbit){
    display: none;
}
.isQbit ::v-deep(.block.qbit){
    display: block;
}
.isQbit ::v-deep(.block.nidl){
    display: none;
}


@media only screen and (max-width: 1200px) {
    .page-layout{
        min-width: initial;
        width: 100%;
    }
    
}

@media only screen and (max-width: 800px) {
    
    ::v-deep(.page-view>h1){
        /* margin: 1rem 0.5rem;
        padding: 0.5rem 1rem; */
        font-size: 1.5rem;
    }


    .main-layout{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: max-content minmax(max-content,1fr) max-content;
        grid-template-areas: 
            'side header'
            'container container'
            'footer footer'
            ;
    }
    
    #main-header {
        grid-area: side/side/side/header;
    }
    
    ::v-deep(h1:after){
        background: linear-gradient(to right, transparent, grey 5%, transparent);
    }
}

@media only screen and (max-width: 600px) {
}
</style>