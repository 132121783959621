import { setupRouterBase,createCmsPageRoutes } from '@common/router';
import MainLayout from '../views/MainLayout.vue';

export function setupRouter(i18n){
    const locales = {};
    locales.cz = i18n.global.getLocaleMessage('cz');
    locales.en = i18n.global.getLocaleMessage('en');

    const routesLocale = [
        {
            path: '',
            component: MainLayout,
            children: [
                {
                    path: '',
                    name: 'home',
                    component: () => import(/* webpackChunkName: "home", webpackPreload: true */'../views/Home.vue'),
                    meta: {
                        public: true,
                        breadcrumbs: [
                            {
                                name: '!Home',
                            }
                        ],
                    },
                },
                {
                    path: "",
                    component: () => import(/* webpackChunkName: "page-layout" */'../views/PageLayout.vue'),
                    children: createCmsPageRoutes({
                        locales,
                    }),
                },   
            ]
        },
        
    ]


    return setupRouterBase({routes:routesLocale,i18n,SUPPORT_LOCALES: ['cz']})
}
