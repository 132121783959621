<template>
    <RouterLinkLocal class="top-logo" :link="''">
        <img height="128" width="128" alt="NW logo" src="@nidl/assets/logo.svg">
        <span v-if="config.qbit" >q-bit s.r.o.</span>
        <span v-else>NW</span>
    </RouterLinkLocal>
</template>

<script>
import config from 'config'

export default {
    name: "TopLogo",
    setup(){
        return {
            config
        }
    }
}
</script>

<style scoped>

.top-logo{
    position: relative;
    text-decoration: none;
    color: #00F;
    /* color: var(--primary-color); */
    /* border-radius: 1rem; */
    z-index: 1;
    font-size: 2rem;
    font-weight: 700;
    display: flex;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    align-items: center;
    width: 20.5rem;
}

.top-logo img{
    height: 100%;
    margin-right: -6%;
    margin-top: 2rem;
    margin-left: 2rem;
}
.top-logo span{
    margin-bottom: 1rem;
}

@media only screen and (max-width: 800px) {
    .top-logo{
        font-size: 1.2rem;
        height: 2.3rem;
        width: unset;
    }
    
.top-logo img{
    margin-right: -2.3rem;
    margin-top: 1rem;
    margin-left: 0;
}
    .top-logo span{
        margin-bottom: 0.5rem;
    }
}
</style>