<template>
    <nav class="menu" :aria-label="menu.name">
        <button 
            v-if="mobile_hidden" 
            :id="'menu'+_.uid"
            type="button" 
            class="button only-mobile"
            @click="toggleMenu()"
            aria-haspopup="true"
            :aria-label="$t('cms.show_menu')"
            :class="{'button-close':showmenu}" 
        >
            <div :class="button_type" ></div>
        </button>
        <MenuList 
            :aria-controls="mobile_hidden ? 'menu'+_.uid : ''"
            :num_of_layers="num_of_layers" 
            :items="menuItems" 
            :layer_hidden="layer_hidden" 
            :class="{mobile_hidden,horizontal,showmenu}" 
            :aria-label="menu.name"
        />
    </nav>
</template>

<script>
import MenuList from './MenuList';
import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

export default {
    name: "Menu",
    components: {
        MenuList,
    },
    props: {
        menu_key: {
            type: String,
            required: true,
        },
        num_of_layers: {
            type: Number,
            default: () => -1,
        },
        layer_hidden: {
            type: Number,
            default: () => 0,
        },
        horizontal: {
            type: Boolean,
            default: () => true,
        },
        mobile_hidden: {
            type: Boolean,
            default: () => true,
        },
        button_type: {
            type: String,
            default: () => "hamburger"
        }
    },
    setup(props){
        const store = useStore();

        const menu = computed(() => store.getters['project/getMenu'](props.menu_key));
        
        const menuItems = computed(() => {
            if(menu.value && menu.value.menu_items){
                return menu.value.menu_items;
            }
            return [];
        });

        const showmenu = ref(false);
        const toggleMenu = () => {
            showmenu.value = !showmenu.value;
        }

        const router = useRouter();
        watch(() => router.currentRoute.value,() => showmenu.value = false);

        return {
            menuItems,
            showmenu,
            toggleMenu,
            menu
        }

    }
}
</script>

<style scoped>
.horizontal{
    display: flex;
    flex-direction: row;
    margin: 0;
    align-items: stretch;
    height: 100%;
    padding: 0.5rem;
}
.menu{
    overflow: hidden;
}
.button{
    position: relative;
    cursor: pointer;
    height: 100%;
    padding: 0.5rem 1rem;
    background-color: inherit;
    display: block;
    border: none;
}
.hamburger, .hamburger::before, .hamburger::after{
    position: relative;
    width: 1.5rem;
    height: 0.25rem;
    border-radius: 4px;
    background-color:black;
    background-color:var(--dark-color);
    display: block;
    content: '';
    transition: transform 0.4s ease;
}
.hamburger::before{
    top: -0.45rem;
}
.hamburger::after{
    top: 0.25rem;
}
.button-close .hamburger{
  background-color: transparent;
}
.button-close .hamburger::before{
  -webkit-transform: rotate(-45deg) translate(-0.7rem,0);
  transform: rotate(-45deg) translate(-0.7rem,0);
    top: -0.45rem;
}
.button-close .hamburger::after{
  -webkit-transform: rotate(45deg) translate(-0.7rem,0);
  transform: rotate(45deg) translate(-0.7rem,0);
    top: 0.25rem;
}
.arrow{
    width: 15px;
    height: 15px;
    border: 3px solid black;
    border: 3px solid var(--dark-color);
    border-left: 0;
    border-top: 0;
    transform: translate(0,-25%) rotate(45deg); 
    background: transparent;
    transition: transform 0.4s ease;
    margin: auto;
}
.button-close .arrow{
     transform: translate(0,25%) rotate(-135deg); 
}

@media only screen and (min-width: 800px) {
    .only-mobile{
        display: none;
    }
}
@media only screen and (max-width: 800px) {
    .mobile_hidden{
        position: absolute;
        left: -1000rem;
        /* right: 0; */
        height: 0;
        max-height: 0;
        transition: max-height 0.4s ease;
        padding: 0;
        overflow: hidden;
    }
    .mobile_hidden.showmenu{
        width: 100%;
        height: initial;
        max-height: 100vh;
        left: 0;
    }
}
</style>