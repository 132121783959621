import { httpService } from "@common/services/http.service";
import config from 'config'

export default function(router){
    const axiosConfig = {
        baseURL: config.apiUrl,
    }
    const httpClient = httpService(axiosConfig);

    const relUrl = () => {
        let url = router.currentRoute.value.params.lang+'/'+config.project_key+'/v1/project';
        return url;
    };

    const state = {
        project: {},
        loading: 0
    }

    const getters = {
        getProject: (state) => state.project,
        getLayoutContent: (state) => (key) => {
            if(!state.project.cms_base_1 || !Array.isArray(state.project.cms_base_1.contents)){
                return {};
            }
            for(let content of state.project.cms_base_1.contents){
                if(content.layout_key === key){
                    return content;
                }
            }
            return {};
        },
        getMenu: (state) => (key) => {
            if(!state.project.cms_base_1 || !Array.isArray(state.project.cms_base_1.menus)){
                return [];
            }
            for(let menu of state.project.cms_base_1.menus){
                if(menu.layoutKey){
                    if(menu.layoutKey === key){
                        return menu;
                    }
                } else if(menu.item_key === key){
                    return menu;
                }
            }
            return {};
        },
        isLoaded: (state) => state.loading === 0,
        isError: (state) => state.loading === -1,
    }

    const actions = {
        fetchProject({ commit,dispatch }){
            commit('loading',1);
            return httpClient.get(relUrl()+"/settings")
                .then(response => {
                    commit('setProject',response.data);
                    commit('loading',0);
                },
                error => {
                    commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                })
        },
    }

    const mutations = {
        setProject: (state,project) => state.project = project,
        loading: (state,status) => state.loading = status,
    }

    return {
        namespaced: true,
        state: state,
        getters: getters,
        actions: actions,
        mutations: mutations
    };

}