<template>
    <header class="main-header">
        <div class="page-container">
            <TopLogo />
            <Menu id="header_menu" :menu_key="'header_menu'" />
        </div>
    </header>
</template>

<script>
import Menu from "@common/components/cms_app/menu/Menu"
import TopLogo from '@nidl/components/layout/TopLogo';

export default {
    name: "MainHeader",
    components: {
        Menu,
        TopLogo
    },
    setup(){
        return {
        }
    }
}
</script>

<style scoped>

.main-header {
    white-space: nowrap;
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: #FFCC99;
    background-color: var(--light-color);
    background-image: linear-gradient(45deg, #FFCC99 10%, transparent),
        linear-gradient(0, #FFCC99 10%, transparent),
		repeating-linear-gradient(135deg, #FFCC99 0%, #ffcc998c 5%, transparent 5%, transparent 10%),
		repeating-linear-gradient(135deg, #ffcc995e 0%, #ffcc9979 5%, transparent 5%, transparent 10%);
    background-size: 100% 100%,100% 100%,10rem 10rem, 7rem 7rem;
    height: 8rem;
    
    box-shadow: 1px 1px 10px -4px #6b6ba3;
    box-shadow: 1px 1px 10px -4px var(--shadow-color);
}
.page-container{
    text-align: right;
    display: flex;
    align-items: stretch;
    height: 100%;

}
.link{
    display: none;
    padding: 1.8rem 0 0 1rem;
}
.link>img{
    max-height: 1.6rem;
    /* max-width: 1rem; */
}
::v-deep(.hamburger),::v-deep(.hamburger::before),::v-deep(.hamburger::after){
    background-color:  #fffbf4;
    background-color: var(--primary-color);
}
::v-deep(.menu){
    color:  #fffbf4;
    color: var(--light-color);
}

.stretch{
    flex-grow: 1;
}
#header_menu ::v-deep(.horizontal){
    align-items: flex-end;
}
#header_menu ::v-deep(.nav-item a){
    color: #000024;
    color: var(--dark-color);
    background: #fffbf4;
    background: var(--white);
    padding: 0.5rem 1rem;
    margin: 0;
    /* width: 6rem; */
    text-align: center;
    font-weight: 700;
    display: block;
    
    box-shadow: 1px 1px 3px 0px  #6b6ba3;
    box-shadow: 1px 1px 3px 0px  var(--shadow-color);
}


#header_menu ::v-deep(.nav-item.level0>a){
    margin: 0.5rem;
    border-radius: 0.2rem;
}

#header_menu ::v-deep(.nav-item.level0 .menu-list){
    box-shadow: 0px 0px 5px -1px var(--dark-color);
    border: 1px solid gray;
    background: #E2E8D4;
    background: var(--primary-color);
}

@media only screen and (max-width: 800px) {
    .main-header{
        height: 3rem;
    }
    
    .page-container{
        justify-content: space-between;
    }
    #header_menu ::v-deep(.menu-list.showmenu){
        background: #1d1b1a;
        background: var(--secondary-color);
        padding: 0;
        margin: 0;
        height: initial;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-bottom: 4px solid #d29455;
    /* box-shadow: 0 0 5px 2px var(--dark-color), 0 0 15px 10px var(--light-color); */
        
        z-index: 20;
    }

    #header_menu ::v-deep(.nav-item a){
        width: initial
    }

    #header_menu ::v-deep(.nav-item.level0>a){
        margin: 0.5rem;
        padding: 0.5rem;
    }
    
}

</style>
