<template>
    <div class="line"></div>
    <div class="copyright">
        Copyright @{{year}}, {{ $t("cms.all_rights_reserved") }} <br />
        {{ $t('cms.powered_by') }} <a href="https://www.agneweb.cz" target="blank">AgneWeb</a>
    </div>
</template>

<script>
import { computed } from 'vue';
export default {
    name: "Copyright",
    setup() {
        const year = computed(() => {
            var d = new Date();
            return d.getFullYear();
        });

        return {
            year
        }
    }
}
</script>

<style scoped>

.line{
    content: " ";
    width: 60%;
    height: 1px;
    margin: 1rem auto 0 auto;
    background: linear-gradient(to right, transparent, grey, transparent);
}
.copyright{
    text-align: center;
    font-size: 0.8rem;
    padding: 0.5rem
}
</style>